import React from "react"
import { PageRendererProps } from "gatsby"
import styled from "styled-components"
import SEO from "@/components/SEO"

const title = "NotFound"
const description = "Page not found"

type NotFoundPageProps = PageRendererProps

const NotFound: React.FC<NotFoundPageProps> = ({ location }) => (
  <>
    <SEO title={title} description={description} pathname={location.pathname} />
    <Root>
      <Title>Page not found</Title>
      <Content>
        <h2>お探しのページはありません</h2>
      </Content>
    </Root>
  </>
)

const Root = styled.div`
  background-color: ${(props) => props.theme.secondaryLightColor};

  @media ${(props) => props.theme.maxSmDown} {
    padding: 80px 0 60px;
  }
  @media ${(props) => props.theme.minMdUp} {
    padding: 130px 15px 100px;
  }
`

const Title = styled.h1`
  font-family: "Teko", sans-serif;
  font-weight: normal;
  text-transform: uppercase;
  text-align: center;

  @media ${(props) => props.theme.minMdUp} {
    font-size: 60px;
    margin-bottom: 30px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    font-size: 40px;
    margin-bottom: 20px;
  }
`

const Content = styled.div`
  background-color: #fff;
  margin: 0 auto;
  line-height: 1.75;

  @media ${(props) => props.theme.minMdUp} {
    padding: 40px;
    max-width: 720px;
  }

  @media ${(props) => props.theme.maxSmDown} {
    padding: 30px 20px;
    border-radius: 2px;
  }

  h2 {
    font-size: 24px;
    text-align: center;
    font-weight: normal;
    margin-bottom: 20px;
  }

  p + p {
    margin-top: 20px;
  }
`

export default NotFound
